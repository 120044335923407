import React, { useLayoutEffect } from 'react'
import SeedBanner from './seedBanner'
import SeedManagement from './seedManagement'
import SeedClients from './seedClients'
import SeedStrength from './seedStrength'
import SeedProcess from './seedProcess'
import SeedTestimonials from './seedTestimonials'
import SeedCaseStudy from './seedCaseStudy'
import SeedCTA from './seedCTA'
import SeedGallery from './seedGallery'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'

gsap.registerPlugin(ScrollToPlugin)

const SeedAssure = () => {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <main>
      <SeedBanner />
      <SeedManagement />
      <SeedClients />
      <SeedGallery />
      <SeedStrength />
      <SeedProcess />
      {/* <SeedTestimonials /> */}
      {/* <SeedCaseStudy /> */}
      <SeedCTA />
    </main>
  )
}

export default SeedAssure
