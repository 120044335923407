import React, { useEffect } from 'react'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

function Management({
  title,
  para1,
  para2,
  image,
  list,
  component,
  hideList,
  extras,
  imageAlign = 'left'
}: {
  title: JSX.Element
  para1: JSX.Element
  para2: JSX.Element
  image: string
  list?: any[]
  component?: string
  hideList?: boolean
  extras?: JSX.Element,
  imageAlign? : 'left' | 'right' 
}) {
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.management-opacity-anim',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: 'back.out',
          stagger: 0.2,
          scrollTrigger: {
            trigger: '.management-opacity-anim',
            start: 'top 90%',
            toggleActions: 'play none none reverse',
          },
        },
      )
      if (!hideList) {
        gsap.fromTo(
          '.management-card-container',
          {
            opacity: 0,
            scale: 0.5,
          },
          {
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: 'back.out',
            stagger: 0.2,
            scrollTrigger: {
              trigger: '.management-card-container',
              start: 'top 90%',
              toggleActions: 'play none none reverse',
            },
          },
        )
      }
    })

    return () => {
      ctx.revert()
    }
  }, [hideList])

  return (
    <section>
      <h2 className="management-opacity-anim text-green pb-8 hidden md:block lg:hidden font-semibold tracking-tighter md:text-3xl md:max-w-md ">
        {title}
      </h2>
      <section className="md:grid grid-cols-2 md:gap-10 2xl:gap-20">
        {
          imageAlign === "right" && 
          <div className="pt-10 md:p-0 sm:max-w-md sm:mx-auto lg:max-w-none lg:pt-2.5 ">
            <img
              src={image}
              alt="Crop Management"
              className="management-opacity-ani"
            />
          </div>
        }
        <article className="">
          <div className="sm:max-w-md sm:mx-auto sm:text-center md:text-left lg:mx-0 lg:max-w-2xl">
            <h2 className="management-opacity-anim text-green font-semibold tracking-tight textgreen 2xl:-mt-1 max-w-md text-3xl md:hidden xl:text-[36px] pb-6 lg:pb-6 lg:block 2xl:text-[3rem] xl:max-w-md 2xl:max-w-2xl xl:pb-7">
              {title}
            </h2>
            <p className="management-opacity-anim pb-4 xl:text-lg lg:max-w-2xl xl:max-w-lg 2xl:max-w-2xl xl:pb-6">
              {para1}
            </p>
            <p className="management-opacity-anim xl:text-lg lg:max-w-2xl xl:max-w-lg 2xl:max-w-2xl">
              {para2}
            </p>
          </div>
          {extras && <>{extras}</>}
        </article>
        {
          imageAlign === 'left' &&
          <div className="pt-10 md:p-0 sm:max-w-md sm:mx-auto lg:max-w-none lg:pt-2.5 ">
            <img
              src={image}
              alt="Crop Management"
              className="management-opacity-ani"
            />
          </div>
        }
      </section>

      {!hideList && (
        <section className="pt-4 md:pt-14 md:grid md:grid-cols-2 md:gap-x-12 md:gap-y-8 max-w-xs mx-auto md:mx-0 md:max-w-4xl xl:max-w-7xl lg:gap-x-16 xl:gap-x-24 xl:gap-y-12 xl:pt-20">
          {list?.map((list, index) => {
            const key = `${index}`
            return (
              <ManagementList
                key={key}
                text={list.text}
                image={list.image}
                component={component}
              />
            )
          })}
        </section>
      )}
    </section>
  )
}

export default Management

function ManagementList({
  text,
  image,
  component,
}: {
  text: string
  image: string
  component?: string
}) {
  return (
    <article className="management-card-container flex flex-col justify-center items-center pt-8 pb-4 md:flex-row md:py-0 md:gap-4 md:justify-start xl:gap-8">
      <img
        src={image}
        alt={`${text.slice(0, 15)}...`}
        className={`
        ${
          component === 'crop' &&
          'h-[5.8rem] md:h-20 xl:h-[5.5rem] 2xl:h-24 mb-4 md:mb-0'
        }
        ${
          component === 'source' &&
          'h-20 md:h-16 xl:h-[4.7rem] 2xl:h-[4.8rem] mb-4 md:mb-0'
        }
        ${
          component === 'seed' &&
          'h-24 md:h-16 xl:h-[5.4rem] 2xl:h-[5.4rem] mb-4 md:mb-0'
        }
          `}
      />
      <p className="font-medium text-center md:text-left text-lg text-green md:text-[1rem] xl:text-[1.3rem] lg:max-w-[27.5rem]">
        {text}
      </p>
    </article>
  )
}
