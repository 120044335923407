import { useLayoutEffect } from "react"
import Goals from './goals'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'
import './index.css'
import CsrOrganizations from "./csrOrganizations"
import Reports from "./Reports"
import CsrBanner from "./csrbanner"
import QRCode from "./QRCode"

gsap.registerPlugin(ScrollToPlugin)

const CSR = () => {
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
          gsap.to(window, { scrollTo: 0 })
        })
    
        return () => ctx.revert()
    }, [])
    return (
        <main>
            <CsrBanner />
            <Goals/>
            <CsrOrganizations/>
            <Reports/>
            <QRCode/>
        </main>
    )
}

export default CSR