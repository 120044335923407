import React, { useState } from 'react';

const data = [
  {
    id: 0,
    title: '1 EGM-1st April 24',
    fileUrl: './assets/meetings/1.EGM_Notice_1Apr24.pdf',
    // transcriptUrl: './assets/transcripts/01EGM_Transcript_1Apr24.pdf'
  },
  {
    id: 1,
    title: '2 EGM-26th July 24',
    fileUrl: './assets/meetings/2.EGM_Notice_26July24.pdf'
  },
  {
    id: 2,
    title: '8th Annual General Meeting Notice',
    fileUrl: './assets/meetings/8thAnnualGeneralMeeting.pdf',
    // transcriptUrl: './assets/transcripts/8thAGM_Transcript.pdf'
  },
  {
    id: 3,
    title: '3 EGM-6th September 24',
    fileUrl: './assets/meetings/3.EGM_Notice_6Sep24.pdf'
  },
  {
    id: 4,
    title: '4 EGM-23rd September 24',
    fileUrl: './assets/meetings/4EGM_Notice_23Sep24.pdf'
  },
  {
    id: 5,
    title: '5 EGM-6th February 25',
    fileUrl: './assets/meetings/5.EGM_Notice_6Feb25.pdf',
    transcriptUrl: './assets/meetings/5EGM-BharatRohan-13b79e00-8854-4335-802f-2fcd8c4045ec-2025-02-06T10_57_23.936Z.pdf'
  },
  {
    id: 6,
    title: '6 EGM-6th March 25',
    fileUrl: './assets/meetings/6.EGM_Notice_6Mar25.pdf',
    transcriptUrl: './assets/meetings/meeting_saved_closed_caption.txt'
  }
];

const ITEMS_PER_PAGE = 5;

export default function Meetings() {
  const [selected, setSelected] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
  const currentData = data.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="py-8 lg:pb-10">
      <div className="flex flex-col lg:flex-row items-start gap-x-12 xl:gap-x-16 2xl:gap-x-20 gap-y-6 md:gap-y-12">
        <div className="grid w-full blade-top-padding-sm lg:pt-0 lg:w-[100%] gap-y-4 lg:gap-y-6 flex-col">
          <div
            className="flex flex-col gap-y-4"
            style={{ minHeight: `${ITEMS_PER_PAGE * 4}rem` }}
          >
            {currentData.map((item, index) => (
              <div key={item.id} className='flex sm:flex-wrap'>
                {
                  !item.transcriptUrl && (
                    <div className="md:w-[70%] sm:w-2">
                      <div
                        onClick={() => setSelected(index)}
                        className={`flex w-full py-4 border px-4 sm:px-6 md:px-8 lg:px-4 xl:px-6 border-green items-center justify-between cursor-pointer transition-all duration-1000 ${
                          selected === index ? 'bg-transparent cursor-auto pt-6' : 'bg-transparent'
                        }`}
                      >
                        <a href={item.fileUrl} target='_blank' className="grid w-full grid-cols-[2.5rem_1fr_auto] sm:grid-cols-[3rem_1fr_auto] xl:grid-cols-[4rem_1fr_auto]">
                          <span
                            className={`text-lg lg:text-xl 2xl:text-xl opacity-25 font-bold transition-all duration-1000 ${
                              selected === index ? 'text-black' : 'text-black'
                            }`}
                          >{`0${item.id + 1}`}</span>
                          <h4
                            className={`$ {
                              selected === index ? 'text-green' : 'text-green'
                            } font-semibold text-left transition-all duration-1000 self-center 2xl:text-xl`}
                          >
                            {item.title}
                          </h4>
                        </a>
                      </div>
                    </div>
                  )
                }
                {item.transcriptUrl && (
                  <>
                    <div className="md:w-[50%] sm:w-2">
                      <div
                        onClick={() => setSelected(index)}
                        className={`flex w-full py-4 border px-4 sm:px-6 md:px-8 lg:px-4 xl:px-6 border-green items-center justify-between cursor-pointer transition-all duration-1000 ${
                          selected === index ? 'bg-transparent cursor-auto pt-6' : 'bg-transparent'
                        }`}
                      >
                        <a href={item.fileUrl} target='_blank' className="grid w-full grid-cols-[2.5rem_1fr_auto] sm:grid-cols-[3rem_1fr_auto] xl:grid-cols-[4rem_1fr_auto]">
                          <span
                            className={`text-lg lg:text-xl 2xl:text-xl opacity-25 font-bold transition-all duration-1000 ${
                              selected === index ? 'text-black' : 'text-black'
                            }`}
                          >{`0${item.id + 1}`}</span>
                          <h4
                            className="text-black font-semibold text-left transition-all duration-1000 self-center 2xl:text-xl"
                          >
                            {item.title}
                          </h4>
                        </a>
                      </div>
                    </div>
                    {
                      item.transcriptUrl !== '*' ?
                      <div className="lg:w-[25%] w-[50%] ml-2">
                        <a href={item.transcriptUrl} target='_blank' className="flex w-full pt-6 py-4 border px-3 sm:px-6 md:px-8 lg:px-4 xl:px-6 border-green items-center justify-between cursor-pointer transition-all duration-1000">
                          <h4 className="text-black font-semibold text-left transition-all duration-1000 self-center 2xl:text-xl">
                            Transcript
                          </h4>
                        </a>
                      </div> : 
                      <div className='lg:w-[25%] w-[50%] ml-2'>
                      </div>
                    }
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-center gap-x-2 mt-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={`px-4 py-2 rounded ${
                  currentPage === i + 1 ? 'bg-gray-100 text-black' : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
