import React, { useEffect } from 'react'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

function CsrManagement({
  title,
  para1,
  para2,
  image,
  list,
  component,
  hideList,
  extras,
  imageAlign = 'left'
}: {
  title: JSX.Element
  para1: JSX.Element
  para2: JSX.Element
  image: string
  list?: any[]
  component?: string
  hideList?: boolean
  extras?: JSX.Element,
  imageAlign? : 'left' | 'right' 
}) {
  useEffect(() => {
    const ctx = gsap.context(() => {
    //   gsap.fromTo(
    //     '.management-opacity-anim',
    //     {
    //       opacity: 0,
    //     },
    //     {
    //       opacity: 1,
    //       duration: 1,
    //       ease: 'back.out',
    //       stagger: 0.2,
    //       scrollTrigger: {
    //         trigger: '.management-opacity-anim',
    //         start: 'top 90%',
    //         toggleActions: 'play none none reverse',
    //       },
    //     },
    //   )
      if (!hideList) {
        gsap.fromTo(
          '.paragraph-container',
          {
            opacity: 0,
            scale: 0.5,
          },
          {
            opacity: 1,
            scale: 1,
            duration: 1.5,
            ease: 'back.out',
            stagger: 0.2,
            scrollTrigger: {
              trigger: '.paragraph-container',
              start: 'top 90%',
              toggleActions: 'play none none reverse',
            },
          },
        )
      }
    })

    return () => {
      ctx.revert()
    }
  }, [hideList])

  return (
    <section>
      <h2 className="management-opacity-anim text-green pb-8 hidden md:block lg:hidden font-semibold tracking-tighter md:text-3xl md:max-w-md ">
        {title}
      </h2>
      <section className="md:grid grid-cols-2 md:gap-10 2xl:gap-20">
        {
          imageAlign === "right" && 
          <div className="flex pt-10 md:p-0 sm:max-w-md sm:mx-auto lg:max-w-none lg:pt-2.5 ">
            <img
              src={image}
              alt="Crop Management"
              className="management-opacity-ani h-auto rounded-[15%] shadow-[7px_7px_0px_0px_#e8ba3a]"
            />
          </div>
        }
        <article className="paragraph-container">
          <div className="sm:max-w-md sm:mx-auto sm:text-center md:text-left lg:mx-0 lg:max-w-2xl">
            <h2 className="management-opacity-anim text-green font-semibold tracking-tight textgreen 2xl:-mt-1 max-w-md text-3xl md:hidden xl:text-[36px] pb-6 lg:pb-6 lg:block 2xl:text-[3rem] xl:max-w-md 2xl:max-w-2xl xl:pb-7">
              {title}
            </h2>
            <p className="management-opacity-anim pb-4 xl:text-lg lg:max-w-2xl xl:max-w-lg 2xl:max-w-2xl xl:pb-6">
              {para1}
            </p>
            <p className="management-opacity-anim xl:text-lg lg:max-w-2xl xl:max-w-lg 2xl:max-w-2xl">
              {para2}
            </p>
          </div>
          {extras && <>{extras}</>}
        </article>
        {
          imageAlign === 'left' &&
          <div className="flex pt-10 md:p-0 sm:max-w-md sm:mx-auto lg:max-w-none lg:pt-2.5 ">
            <img
              src={image}
              alt="Crop Management"
              className="management-opacity-ani h-auto rounded-[15%] shadow-[7px_7px_0px_0px_#e8ba3a]"
            />
          </div>
        }
      </section>
    </section>
  )
}

export default CsrManagement
