import { Blogcard } from 'organisms/blogs'

const blogs = [
  {
    "title": "From Deluge to Drought: Rajasthan’s Weather Whirlwind",
    "pubDate": "2023-09-19 10:16:26",
    "link": "https://medium.com/bharatrohan-blog/from-deluge-to-drought-rajasthans-weather-whirlwind-e15a86c8e7fb?source=rss----ab2892480f7f---4",
    "guid": "https://medium.com/p/e15a86c8e7fb",
    "author": "Amandeep Panwar",
    "thumbnail": "",
    "image" : "https://cdn-images-1.medium.com/max/1024/1*jKbnWqb8lwtYv1Cs50XtlA.jpeg"
  },
  {
    "title": "Unravelling the Impact of Agricultural Emissions on Climate Change: A Call to Collaborate for a…",
    "pubDate": "2023-08-07 08:24:24",
    "link": "https://medium.com/bharatrohan-blog/unravelling-the-impact-of-agricultural-emissions-on-climate-change-a-call-to-collaborate-for-a-4ba64b5c2e5b?source=rss----ab2892480f7f---4",
    "guid": "https://medium.com/p/4ba64b5c2e5b",
    "author": "Megha Raju",
    "thumbnail": "",
    "image" : "https://cdn-images-1.medium.com/max/600/1*GHHrWa0SPw5zVXFzMROf6Q.jpeg"
  },
  {
    "title": "Heating up of Climate Crisis to an immediate call of action",
    "pubDate": "2022-08-30 05:04:30",
    "link": "https://medium.com/bharatrohan-blog/heating-up-of-climate-crisis-to-an-immediate-call-of-action-29b85d3d821b?source=rss----ab2892480f7f---4",
    "guid": "https://medium.com/p/29b85d3d821b",
    "author": "Megha Raju",
    "thumbnail": "",
    "image" : "https://cdn-images-1.medium.com/max/945/1*5MlaCUJVs3lCoUeZmmLvNQ.jpeg"
  },
  {
    "title": "Drone Agritech startup BharatRohan to provide Crop insurance under its ‘Pravir Shield’ programme to…",
    "pubDate": "2022-08-05 13:17:33",
    "link": "https://medium.com/bharatrohan-blog/drone-agritech-startup-bharat-rohan-to-provide-crop-insurance-under-its-pravir-shield-programme-to-26c0540886ba?source=rss----ab2892480f7f---4",
    "guid": "https://medium.com/p/26c0540886ba",
    "author": "Panimalar T",
    "thumbnail": "",
    "image" : "https://cdn-images-1.medium.com/max/1024/1*hp2FCz-4JxoONGfx-0Js3A.jpeg"
  },
  {
    "title": "Checking India’s pulses!",
    "pubDate": "2020-08-19 08:12:01",
    "link": "https://medium.com/bharatrohan-blog/checking-indias-pulses-563e446717a4?source=rss----ab2892480f7f---4",
    "guid": "https://medium.com/p/563e446717a4",
    "author": "Amandeep Panwar",
    "thumbnail": "",
    "image" : "https://cdn-images-1.medium.com/max/1024/1*jbxiDhwDWEG2_lxAthSRTw.jpeg",
  },
  {
    "title": "The left-handed agriculture",
    "pubDate": "2020-08-18 13:39:55",
    "link": "https://medium.com/bharatrohan-blog/the-left-handed-agriculture-a1d6fc3993?source=rss----ab2892480f7f---4",
    "guid": "https://medium.com/p/a1d6fc3993",
    "author": "Amandeep Panwar",
    "thumbnail": "",
    "image" : "https://cdn-images-1.medium.com/max/1024/0*22MKv1EDM7ZEi3Uk"
  }

]; // This is the data that will be fetched from the API

const apiUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/bharatrohan-blog'

const Blogs = () => {

  return (
    <section className="py-16 md:py-20 bg-cream3">
      <section className="w-container-lg">
        <h2 className="text-3xl text-center text-green font-semibold md:text-left xl:text-4xl 2xl:text-[3rem]">
          Blogs
        </h2>
        <p className="text-center pt-4 mx-auto max-w-sm md:text-left md:mx-0 xl:text-lg xl:max-w-md 2xl:max-w-xl">
          Stay on top of your farming practices with interesting and beneficial
          agricultural insights and know-how.
        </p>

        <section className="grid md:grid-cols-2 xl:grid-cols-3 gap-6 pt-6 pb-3 xl:gap-x-12 xl:gap-y-8 2xl:gap-x-16">
          {
            blogs.map((elem: any, index: number) => {
              return (
                <div key={index} className="pt-8">
                  <Blogcard data={elem} theme="light" />
                </div>
              )
            })
          }
            
        </section>
      </section>
    </section>
  )
}

export default Blogs
