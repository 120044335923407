
import { useNavigate } from 'react-router-dom'

const QRCode_ = ({
  content,
  target,
  image,
}: {
  content: JSX.Element
  target: string
  image: string
}) => {
  const navigate = useNavigate()

  return (
    <div
      className="flex flex-col-reverse md:grid md:grid-cols-[60%_40%] lg:grid-cols-[70%_30%] gap-8 xl:gap-4"
    >
      
      <div className='text-xl'>
        {content}
      </div> 
      
      <div className="sm:px-28 md:px-0 lg:pr-16 xl:px-4 flex flex-col">
        <img
          src={image}
          alt="Get in touch with us"
        />
        <div
        className='text-center outline-none border-1 border-transparent font-semibold py-3 sm:text-sm md:text-lg transition 
        		font-bold text-green bg-yellow'>
          Scan QR to Contribute Now
        </div>
      </div>
    </div>
  )
}

export default QRCode_