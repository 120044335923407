import qrImage from 'assets/csr/qrcode/qrcode.png'
import gsap from 'gsap';
import Partition from 'molecules/partition';
import Cta from 'organisms/cta'
import QRCode_ from 'organisms/qrCode_';
import { useEffect } from 'react';

const QRCode = () => {
  useEffect(() => {
    const ctx = gsap.context(() => {
			gsap.fromTo(
			  '.qr-code-anim',
			  {
				  x: -150,
			  },
			  {
          duration: 1,
          x: 0,
          ease: 'back.out',
          stagger: 0.1,
          scrollTrigger: {
            trigger: '.qr-code-anim-parent',
            start: 'top 80%',
            toggleActions: 'restart none none reverse',
          },
			  },
			)
		  })
	  
      
		  return () => {
			  ctx.revert()
		  }
  }, [])
  return (
    <section id="qrcode" className="qr-code-anim-parent bg-cream3 py-16 px-4 md:py-20  relative overflow-hidden cta-section xl:bg-[url(assets/globals/cta-bg.svg)] bg-[-80px_-150px] min-[1600px]:bg-[0px_-200px] bg-contain bg-no-repeat">
      <div className='w-container sm:w-container-sm md:w-container'>
        <Partition color="dark" text="CSR Initiatives for a Better Tomorrow" />
        <div className="pb-8"></div>

        <QRCode_
            content={
              <div className='qr-code-anim'>
                <p>
                  <span className='font-bold'>BharatRohan Airborne Innovation Limited</span>, through its CSR initiatives with <span className='font-bold'>LECIN</span> and <span className='font-bold'>TeachEdu</span>, is driving positive change in rural communities. We invite you to support this transformative journey.
                  <br/><br/>
                  Your contributions to the BharatRohan Innovation Foundation will enable technology adoption, education for hearing-impaired children, enhanced Anganwadis, farmer support, medical care in remote areas, and animal welfare through <i>Paws of Compassion</i>.
                  <br/><br/>
                  Join us in bridging gaps in <span className='font-bold'> technology, education, healthcare,</span> and <span className='font-bold'>sustainability</span>. Together, we can create a more equitable future. Be a catalyst for change by supporting our initiatives today!
                </p>

                <div className='mt-5 text-sm'>
                  
                  <a href='https://forms.zohopublic.in/virtualoffice98001/form/DonationFormBharatRohanInnovationFoundation/formperma/pOyDYrUJdvTZF6iSizVbuuwQwdisSsYuNt3JuP_FdVI' target='_blank' 
                  className='outline-none border-1 border-transparent font-semibold rounded-full px-8 py-2 cursor-pointer sm:text-sm md:text-lg transition 
                  font-bold text-green bg-yellow hover:bg-green hover:text-yellow'>Open Form </a>
                  <p className='p-2 px-0'>
                  (Fill this form and attach the screenshot here after making a successful contribution)
                  </p>
                </div>
              </div>
            }
            image={qrImage}
            target="/contact-us"
          />
        </div>
    </section>
  )
}

export default QRCode;