import React from 'react'

function Banner({
  highlighted,
  title,
  desc,
  banner,
  align,
  bgPosition,
  button
}: {
  highlighted: string
  title: JSX.Element
  desc: JSX.Element
  banner: string
  align?: 'left'
  bgPosition?: string
  button? : JSX.Element
}) {
  return (
    <section className="relative w-full bg-green overflow-x-hidden">
      {/* <video
        autoPlay
        loop
        muted
        playsInline
        className="h-[100vh] w-[100vw] object-cover sm:h-[70vh] md:h-[80vh] lg:h-[100vh] lg:w-full"
      >
        <source src={banner} />
      </video> */}
      <img
        src={banner}
        aria-hidden
        alt={highlighted}
        className={`h-[100vh] w-[100vw] object-cover sm:h-[70vh] md:h-[80vh] lg:h-[100vh] lg:w-full
          ${bgPosition}
        `}
      />
      <div className="absolute inset-0 bg-black bg-opacity-20 lg:hidden"></div>
      <div
        className={`absolute inset-0 w-container-lg flex flex-col justify-center text-white h-fit top-1/2 -translate-y-1/2 ${
          align === 'left' ? 'items-center md:items-start' : 'items-center'
        }`}
      >
        <h1
          className={`text-appear-anim px-3 sm:px-0 tracking-tight text-center font-medium text-[2rem] sm:text-3xl md:text-4xl md:pb-2 2xl:text-5xl ${
            align === 'left' && 'md:text-left'
          }`}
        >
          {title}
        </h1>
        <h3
          className={`text-appear-anim-delayed text-[1rem] leading-normal text-center sm:text-base text-white text-opacity-100 lg:text-opacity-90 pt-5 xl:text-xl 2xl:text-[1.4rem] ${
            align === 'left' && 'md:text-left pt-8'
          }`}
        >
          {desc}
        </h3>
        <br/>
        
        {button}
      </div>
    </section>
  )
}

export default Banner

export const BannerHighlightedText = ({
  highlighted,
}: {
  highlighted: string
}) => {
  return (
    <div className=" relative inline-block top-3 sm:top-5 text-center">
      {/* text-appear-anim-scale-up */}
      <svg
        className="h-[52px] sm:h-14 md:h-16 2xl:h-20"
        viewBox="0 0 209 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_1_57)">
          <path
            d="M0 0H192.5L209 17V78L18.5 75.5L0 61V30.3041V0Z"
            fill="white"
            fillOpacity="0.1"
          />
          <path
            d="M1.5 1.5H191.866L207.5 17.6082V76.4802L19.0264 74.0068L1.5 60.2698V30.3041V1.5Z"
            stroke="white"
            strokeWidth="3"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_1_57"
            x="-10"
            y="-10"
            width="229"
            height="98"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1_57"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1_57"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <strong className="absolute inset-0 text-yellow">{highlighted}</strong>
    </div>
  )
}
