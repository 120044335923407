import { useRef, useState } from 'react'

import left1S1 from 'assets//csr/collage/slide1/left1.png'
import left2S1 from 'assets//csr/collage/slide1/left2.jpg'
import rightS1 from 'assets/csr/collage/slide1/right.png'
import goalS1 from 'assets/csr/collage/slide1/logo.png'

import left1S2 from 'assets//csr/collage/slide2/left-teach1.png'
import left2S2 from 'assets//csr/collage/slide2/left-teach2.jpg'
import rightS2 from 'assets/csr/collage/slide2/right.png'
import goalS2 from 'assets/csr/collage/slide2/logo.png'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

const goalsList = [
  {
    goal: goalS1,
    left1: left1S1,
    left2 : left2S1, 
    right: rightS1,
    color: '#EF353C',
    alt: 'LECIN Image',
  },
  {
    goal: goalS2,
    left1: left1S2,
    left2 : left2S2, 
    right: rightS2,
    color: '#EF353C',
    alt: 'LECIN Image',
  },
  // {
  //   goal: goalS1,
  //   left1: left1S1,
  //   left2 : left1S1, 
  //   right: rightS1,
  //   color: '#EF353C',
  //   alt: 'LECIN Image',
  // },
]

const Goals = () => {
  const [currActiveIndex, setCurrActiveIndex] = useState<number>(0)
  const swiperRef = useRef<any>(null)

  return (
    <section className="">
      {/* For desktop */}
      <section className="hidden lg:block relative max-h-screen">
        <Swiper
          className="relative max-h-screen"
          direction="vertical"
          // autoHeight
          speed={700}
          navigation={{
            nextEl: '.goals-sus-next-btn',
            prevEl: '.goals-sus-prev-btn',
          }}
          onSlideChange={(swiper) => {
            setCurrActiveIndex(() => swiper.activeIndex)
          }}
          modules={[Navigation, Pagination]}
          initialSlide={0}
          slidesPerView={1}
        >
          {goalsList.map((goal, index) => {
            return (
              <SwiperSlide key={index} className="">
                <GoalLister
                  key={index}
                  goal={goal.goal}
                  alt={goal.alt}
                  color={goal.color}
                  left1={goal.left1}
                  left2={goal.left2}
                  right={goal.right}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>

        {/* Swiper control buttons */}
        <div className="absolute px-2.5 py-3.5 xl:px-3 xl:py-4 backdrop-blur-sm bg-dark bg-opacity-40 border-2 border-white top-1/2 -translate-y-1/2 right-12 text-white z-10 flex flex-col rounded-full overflow-hidden">
          <button
            className={`goals-sus-prev-btn p-3 rounded-full transition-all duration-300
            ${
              currActiveIndex === 0
                ? 'bg-transparent cursor-not-allowed'
                : 'bg-yellow hover:bg-green active:bg-[#22772693]'
            }
          `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23 24"
              fill="none"
              className="rotate-180  h-4 xl:h-6"
            >
              <path
                d="M9.61627 1.87329C9.61627 1.04058 10.2913 0.365537 11.124 0.365537C11.9567 0.365537 12.6318 1.04058 12.6318 1.87329L9.61627 1.87329ZM12.1902 23.4412C11.6014 24.03 10.6467 24.03 10.0579 23.4412L0.462592 13.8459C-0.126223 13.2571 -0.126223 12.3024 0.462592 11.7136C1.05141 11.1248 2.00606 11.1248 2.59488 11.7136L11.124 20.2427L19.6532 11.7136C20.242 11.1248 21.1966 11.1248 21.7855 11.7136C22.3743 12.3024 22.3743 13.2571 21.7855 13.8459L12.1902 23.4412ZM12.6318 1.87329L12.6318 22.375L9.61627 22.375L9.61627 1.87329L12.6318 1.87329Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="py-5 flex flex-col justify-center items-center text-lg xl:text-xl font-medium">
            <span className="text-yellow">0{currActiveIndex + 1}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="3"
              height="14"
              viewBox="0 0 3 14"
              fill="none"
              className="my-1.5"
            >
              <path
                d="M1.5 1.44922L1.5 12.5504"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <span className="text-white text-opacity-60">
              0{goalsList.length}
            </span>
          </div>
          <button
            className={`goals-sus-next-btn p-3 rounded-full transition-all duration-300
            ${
              currActiveIndex === goalsList.length - 1
                ? 'bg-transparent cursor-not-allowed'
                : 'bg-yellow hover:bg-green active:bg-[#22772693]'
            }
          `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23 24"
              fill="none"
              className=" h-4 xl:h-6"
            >
              <path
                d="M9.61627 1.87329C9.61627 1.04058 10.2913 0.365537 11.124 0.365537C11.9567 0.365537 12.6318 1.04058 12.6318 1.87329L9.61627 1.87329ZM12.1902 23.4412C11.6014 24.03 10.6467 24.03 10.0579 23.4412L0.462592 13.8459C-0.126223 13.2571 -0.126223 12.3024 0.462592 11.7136C1.05141 11.1248 2.00606 11.1248 2.59488 11.7136L11.124 20.2427L19.6532 11.7136C20.242 11.1248 21.1966 11.1248 21.7855 11.7136C22.3743 12.3024 22.3743 13.2571 21.7855 13.8459L12.1902 23.4412ZM12.6318 1.87329L12.6318 22.375L9.61627 22.375L9.61627 1.87329L12.6318 1.87329Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </section>

      {/* For Mobile */}
      <section className="lg:hidden goals py-16 md:py-20 bg-cream">
        <section className="w-container max-w-[27rem] md:max-w-lg mx-auto">
          
          <Swiper
            className="relative max-h-screen py-6"
            speed={700}
            navigation={{
              nextEl: '.goal-next-btn',
              prevEl: '.goal-prev-btn',
            }}
            onSlideChange={(swiper) => {
              setCurrActiveIndex(swiper.activeIndex % goalsList.length)
            }}
            modules={[Navigation, Pagination]}
            initialSlide={0}
            slidesPerView={1}
            pagination={true}
            spaceBetween={20}
          >
            {goalsList.map((goal, index) => {
              return (
                <SwiperSlide key={index} className="">
                  <div className="relative border rounded-xl overflow-hidden mb-6">
                    <img
                      src={goal.right}
                      alt={goal.alt}
                      className="h-full w-full object-cover"
                    />
                    <img
                      src={goal.goal}
                      alt={goal.alt}
                      className="absolute bottom-0 left-0 h-auto w-40 xl:48 2xl:w-60"
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </section>
      </section>
    </section>
  )
}

export default Goals

function GoalLister({
  left1,
  left2,
  right,
  goal,
  color,
  alt,
}: {
  left1: string
  left2: string
  right: string
  goal: string
  color: string
  alt: string
}) {
  return (
    <section className="grid grid-cols-[45%_55%] h-screen w-full relative">
      <div className="grid grid-rows-[47%_53%] w-full h-screen">
        <div
          className=""
          style={{ background: color }}
        >
          <img src={left1} alt={alt} className="h-full w-full object-cover" />
        </div>
        <div className="">
          <img src={left2} alt={alt} className="h-full w-full object-cover" />
          <img
            src={goal}
            alt={alt}
            className="absolute bottom-0 left-0 h-auto w-40 xl:48 2xl:w-60"
          />
        </div>
      </div>
      <div className="">
        <img src={right} alt={alt} className="max-h-screen h-full w-full" />
      </div>
    </section>
  )
}
