import { useEffect } from 'react';
import Banner, { BannerHighlightedText } from '../../organisms/banner';
import banner from 'assets/csr/banner/banner-10.jpg'
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';

export default function CsrBanner() {
  const navigate = useNavigate();
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to('.animated-button', {
        scale: 1.2, // Button grows to 1.2x its size
        duration: 0.8, // Animation duration
        repeat: -1, // Infinite loop
        yoyo: true, // Reverses back to original size
        ease: 'power1.inOut',
      });
    });
  
    return () => {
      ctx.revert();
    };
  }, []);

  const handleClick = () => {
    navigate('/csr#qrcode'); // Change URL
    setTimeout(() => {
      const element = document.querySelector('#qrcode');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); // Scroll manually
      }
    }, 100); // Delay to ensure React Router updates first
  };

  
  return (
    <Banner
      highlighted="CropAssure banner"
      title={
        
        <span className="inline-block font-semibold max-w-[20rem] md:max-w-md lg:max-w-none">
          At <span className="text-yellow">BharatRohan</span>, we believe true progress goes beyond transforming agriculture,
          <br className="hidden lg:block" />
          
          it’s <span  className="text-yellow">about uplifting communities and driving sustainable change.</span> 
        </span>
      }
      desc={
        <span className="inline-block max-w-[18rem] sm:max-w-[28rem] xl:max-w-xl 2xl:max-w-[50rem]">
          Through our <span className='font-bold'>CSR initiatives</span>, we actively support organizations dedicated to <span className='font-bold'>education and skill development</span>, creating a <span className='font-bold'>lasting impact</span>.
        </span>
      }
      banner={banner}
      button={<div className="text-appear-anim text-center mt-3">
        <button
          // href="/csr#qrcode"
          onClick={handleClick}
          className="animated-button hover:bg-green hover:text-yellow outline-none border border-transparent font-semibold rounded-full px-6 py-3 cursor-pointer text-sm transition 
             text-xl text-green bg-yellow"
        >
          Contribute Now
        </button>
      </div>
      }
      align='left'
    />
  )
}

function SlidingWords() {
  return (
    <span className="font-semibold text-yellow inline-flex flex-col min-h-[70px] max-h-[70px] h-[70px] overflow-hidden">
      <span className="animate-words min-h-full max-h-full h-full">
        purpose
      </span>
      <span className="animate-words min-h-full max-h-full h-full">
        passion
      </span>
      <span className="animate-words min-h-full max-h-full h-full">people</span>
      <span className="animate-words min-h-full max-h-full h-full">
        ambition
      </span>
      <span className="animate-words min-h-full max-h-full h-full">
        purpose
      </span>
    </span>
  )
}

{/*  */}
