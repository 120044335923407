import gsap from "gsap"
import { useEffect } from "react"
interface Report {
	title : string,
	url : string
}

const Reports = () => {
	const reports : Report[] = [
		{
			title : 'CSR Annual Action Plan for FY 2024-2025',
			url : 'https://bharatrohan-official.blr1.digitaloceanspaces.com/CSR%20Annual%20Action%20Plan%20FY24-25.pdf'
		}
	]
	useEffect(() => {
		const ctx = gsap.context(() => {
			gsap.fromTo(
			  '.reports',
			  {
				y: 150,
			  },
			  {
				duration: 1,
				y: 0,
				ease: 'back.out',
				stagger: 0.1,
				scrollTrigger: {
				  trigger: '.reports-parent',
				  start: 'top 80%',
				  toggleActions: 'restart none none reverse',
				},
			  },
			)
		  })
	  
		  return () => {
			ctx.revert()
		  }
	}, [])
  return (
	<section className="bg-green relative">
		{/* <img
			src={topLeft}
			alt="Contact us"
			className="absolute top-0 left-0 hidden xl:block h-auto w-60 2xl:w-96 min-[1800px]:w-auto"
		/>
		<img
		src={bottomRight}
		alt="Contact us"
		className="absolute bottom-0 right-0 hidden xl:block h-auto w-60 2xl:w-96 min-[1800px]:w-auto"
		/> */}
		<section className="py-16 px-4 w-container sm:w-container-sm md:w-container reports-parent">
			<h2 className="text-3xl text-center font-semibold md:text-left xl:text-4xl 2xl:text-[3rem] text-cream ">Year Wise Reports</h2>
			<p className='mb-10'></p>
			{
				reports.map((report : Report) => 
					<article className='reports flex justify-between bg-cream py-4 md:px-6 px-2 rounded-xl mb-3'> 
						<p className="self-center text-[13px] md:text-base lg:text-lg"> {report.title} </p>

						<a href={report.url} target='_blank' 
							className='outline-none border border-transparent font-semibold rounded-full sm:px-6 px-4 py-2 cursor-pointer text-sm 
							font-bold text-green bg-yellow hover:bg-green hover:text-yellow transition w-auto min-w-20 max-w-fit 
							whitespace-nowrap text-center inline-flex items-center justify-center self-center h-auto'>
							View
						</a>
					</article>
			
				)
			}
		</section>
	</section>
  )







  
}

export default Reports