import lecin from 'assets/csr/companies/lecin.jpeg'
import teach from 'assets/csr/companies/teach.png'
import bg from 'assets/cropAssure/management/bg.png'
// import Management from 'organisms/management'
import Partition from 'molecules/partition'
import CsrManagement from 'organisms/csrManagement'
interface CSR {
  title: string,
  para1: string,
  para2: string,
  image : string
}
const lists : Array<CSR> = [
  {
    title : 'TEACH (Training and Educational Centre for Hearing Impaired)',
    para1 : 'For individuals with hearing impairments, education and employment opportunities remain limited. TEACH is bridging this gap by providing specialized education, with higher education in commerce, MIS, arts, and accounting, ensuring they gain industry-relevant skills',
    para2 : 'TEACH focuses on mental well-being, sign language proficiency, and employer sensitization, fostering an inclusive ecosystem where individuals with hearing impairments can lead independent and dignified lives.',
    image : teach
  },
  {
    title : 'LECIN (Let’s Educate Children In Need) – Project NEEV',
    para1 : 'Every child deserves a strong educational foundation. LECIN’s Project NEEV strengthens early childhood learning in rural communities by equipping children in Kasna and Pachaytan villages with literacy, numeracy, and social-emotional skills. ',
    para2 : 'The program also trains local educators, empowers women as facilitators, and actively involves parents, ensuring a nurturing learning environment. By investing in early education, LECIN is breaking barriers and opening doors to better opportunities for underprivileged children.',
    image : lecin
  }
]

const CsrOrganizations = () => {
  return (
    <section className="bg-cream3 relative">
      <img
        src={bg}
        alt="plants"
        className="absolute bottom-3 right-0 hidden h-28 w-auto opacity-[15%] lg:h-32 2xl:h-40 md:block min-[1800px]:h-60"
      />
      <section className="py-16 px-4 w-container sm:w-container-sm md:w-container">
	  	<Partition color="dark" text="Partnered Organizations" />
		    <div className="pb-8"></div>
        {
          lists.map((list, index) => 
            <div className={index>0 ? 'mt-20' : ''}>
            <CsrManagement
              title={
                <span className="text-green">
                    {list.title}
                </span>
              }
              para1={
                <>
                  {list.para1}
                </>
              }
              para2={
                <>
                  {list.para2}
                </>
              }
              image={list.image}
              component="csr"
              imageAlign={index%2 === 0 ? 'right' : 'left'}
            />
            </div>
          )
        }
      </section>
    </section>
  )
}

export default CsrOrganizations;
