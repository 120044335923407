import React from 'react'
import { Outlet } from 'react-router-dom'
import {
  PageHome,
  PageError404,
  PageAboutUs,
  PageContact,
  PageTermsAndConditions,
  PagePrivacyPolicy,
  PageCareers,
  SourceAssure,
  SeedAssure,
  CropAssure,
  CarbonFarming,
  Sustainability,
  SupplyChain,
  BlogsAndUpdates,
  CaseStudies,
  IPM,
  Technology,
} from 'screens/index'

import { Cumin, Fennel, Fenugreek, Mustard, Coriander } from 'screens/products'
import {
  Board,
  // Disclosure,
  // Grievance,
  // IPO,
  // Meetings,
  // Policies,
  // Results,
  // Stock,
} from 'screens/investors'

import { Header, Footer } from 'layouts/index'
import Profile from 'screens/investors/Profile'
import NonExecutiveDirectorProfile from 'screens/investors/NonExecutiveProfile'
import CSR from 'screens/csr'

function ViewWithHeaderAndFooter() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

export default function routes() {
  return [
    {
      path: '/',
      element: <ViewWithHeaderAndFooter />,
      children: [
        {
          path: '/',
          element: <PageHome />,
        },
        {
          path: '/contact-us',
          element: <PageContact />,
        },
        {
          path: '/careers',
          element: <PageCareers />,
        },
        {
          path: '/blogs-and-updates',
          element: <BlogsAndUpdates />,
        },
        {
          path: '/technology',
          element: <Technology />,
        },
        {
          path: '/supply-chain',
          element: <SupplyChain />,
        },
        {
          path: '/about-us',
          element: <PageAboutUs />,
        },
        {
          path: '/terms-of-use',
          element: <PageTermsAndConditions />,
        },
        {
          path: '/privacy-policy',
          element: <PagePrivacyPolicy />,
        },
        {
          path: '/crop-assure',
          element: <CropAssure />,
        },
        {
          path: '/source-assure',
          element: <SourceAssure />,
        },
        {
          path: '/seed-assure',
          element: <SeedAssure />,
        },
        {
          path: '/carbon-farming',
          element: <CarbonFarming />,
        },
        {
          path: '/sustainability',
          element: <Sustainability />,
        },
        {
          path: '/case-studies',
          element: <CaseStudies />,
        },
        {
          path: '/ipm',
          element: <IPM />,
        },
        {
          path: '/csr',
          element: <CSR />
        }
      ],
    },
    {
      path: '/investors',
      element: <ViewWithHeaderAndFooter />,
      children: [
        // {
        //   path: '/',
        //   element: <Investors />,
        // },
        {
          path: '',
          element: <Board />,
        },
        {
          path: 'profile/:id',
          element: <Profile />
        },
        {
          path: 'non-executive/:id',
          element: <NonExecutiveDirectorProfile />
        }

      ],
    },

    {
      path: '/products',
      element: <ViewWithHeaderAndFooter />,
      children: [
        {
          path: 'cumin',
          element: <Cumin />,
        },
        {
          path: 'fenugreek',
          element: <Fenugreek />,
        },
        {
          path: 'fennel',
          element: <Fennel />,
        },
        {
          path: 'mustard',
          element: <Mustard />,
        },
        {
          path: 'coriander',
          element: <Coriander />,
        },
      ],
    },
    {
      path: '*',
      element: <ViewWithHeaderAndFooter />,
      children: [{ path: '*', element: <PageError404 /> }],
    },
  ]
}
